.service-parent {
  background-color: white;

  .service-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--gap);



    .titlebox {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--gap);
      justify-content: center;
    }

    .first-box {
      padding: 100px 0px 0px 0px;
      width: 100%;

      .first-tab {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;

        .icon {
          display: flex;
          flex-direction: column;
          border-bottom: 3px solid var(--svg-color);
          width: 100%;
          align-items: center;
          justify-content: center;
          gap: var(--gap);
          padding: 0px 0px 10px 0px;
          cursor: pointer;

          svg {
            height: 50px;
            width: 50px;
            color: var(--svg-color);
            border: 1px solid var(--svg-color);
            padding: 10px;
            border-radius: 50%;
          }

          &:hover {
            svg {
              color: var(--accent);
              border-color: var(--accent);
            }

            border-color: var(--accent);
          }

          &.active {
            svg {
              color: var(--accent2);
              border: solid var(--accent2);
            }

            border-bottom:solid var(--accent2);
          }
        }

      }

    }

    @media only screen and (max-width:800px) {
      .first-box {
        padding-top: 50px !important;

        .first-tab {
          flex-wrap: wrap;
          padding: 0px !important;

          .icon {
            width: 180px;
            margin-top: 20px;
          }
        }
      }

    }

    @media only screen and (max-width:700px) {
      .first-box {
        display: none;
      }

    }

    .second-box {
      padding: 50px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--gap);

      .left {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: var(--gap);
      }

      .right {
        aspect-ratio: 3/2;
        width: 40%;
      }
    }

    @media only screen and (max-width:1000px) {
      .second-box {
        flex-direction: column-reverse;

        .left {
          width: 100%;
        }

        .right {
          width: 60%;
        }
      }
    }

    @media only screen and (max-width:700px) {
      .second-box {
        display: none;
      }
    }

  }

  .mobile-swiper {
    display: none;

    .swiper {
      width: 100%;
      height: 100%;

      .swiper-button-prev,
      .swiper-button-next {
        background: black;
        color: white;
        border-radius: 50%;
        padding: 10px;
        width: 40px;
        height: 40px;

        &::after {
          font-size: 15px;
          font-weight: 700;
        }
      }
    }

    .swiper-slide {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0px;

      .card {
        background: rgba(220, 220, 220, 0.348);
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: var(--gap);
        padding: 10px;
        height: 800px;

        .img-content-box {
          display: flex;
          flex-direction: column;
          gap: var(--gap);

          .card-img1 {
            background: url("../assets//service//1.jpg");
            // width:100%;
            // height:250px;
            aspect-ratio: 5/3;
          }

          .card-img2 {
            background: url("../assets//service//2.jpg");
            // width:100%;
            // height:250px;
            aspect-ratio: 5/3;
          }

          .card-img3 {
            background: url("../assets//service//3.jpg");
            // width:100%;
            // height:250px;
            aspect-ratio: 5/3;
          }

          .card-img4 {
            background: url("../assets//service//4.jpg");
            // width:100%;
            // height:250px;
            aspect-ratio: 5/3;
          }

          .card-img5 {
            background: url("../assets//service//5.jpg");
            // width:100%;
            // height:250px;
            aspect-ratio: 5/3;
          }

          .card-img6 {
            background: url("../assets//service//6.jpg");
            // width:100%;
            // height:250px;
            aspect-ratio: 5/3;
          }

          .content-box {
            display: flex;
            flex-direction: column;
            gap: 10px;

            h2 {
              font-size: 20px;
              font-weight: 700;
              line-height: 28px;
            }
          }

        }
      }
    }
  }

  @media only screen and (max-width:700px) {
    .mobile-swiper {
      padding: 50px 0px;
      display: block;
    }
  }
}

.fixed-img {
  width: 100%;
  height: auto;
  position: relative;
  background-image: url("../assets/bg.jpg");
  background-attachment: fixed;




  .overlay {
    background: rgba(0, 0, 0, 0.377);
    padding: 20px 0px;

    height: 100%;
    width: 100%;

  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(var(--gap));



    .heading {
      display: flex;
      align-items: center;
      // gap: calc(var(--gap)/2);

      span {
        width: 70px;
        height: 2px;
        border-radius: var(--rad);
        background-color: white;
      }

      h2 {
        color: white;
      }
    }


    .para {
      width: 100%;

      p {
        text-align: center;
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .fixed-img {

    .img {
      top: 1px !important;
    }
  }
}