.img-container{
    --pad:10px;

    height: calc(100% - 0px);
    width: calc(100% - 0px);
    background: var(--white);
    padding: var(--pad);
    box-shadow: var(--shadow);
    position: relative;

    .loader-box{
        position: absolute;
        height: calc(100% - (var(--pad)*2));
        width: calc(100% - (var(--pad)*2));
        background-color: var(--loading-grey);
        background: linear-gradient(
          100deg,
          rgba(255, 255, 255, 0) 40%,
          rgba(255, 255, 255, .5) 50%,
          rgba(255, 255, 255, 0) 60%
        ) var(--loading-grey);
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 2s loading ease-in-out infinite;
      }
      
      @keyframes loading {
        to {
          background-position-x: -20%;
        }
    }

    .img-box2{
        position: absolute;
        height: calc(100% - (var(--pad)*2));
        width: calc(100% - (var(--pad)*2));
        z-index: 2;
    }
    .overlay-box{
        position: absolute;
        height: calc(100% - (var(--pad)*2));
        width: calc(100% - (var(--pad)*2));
        z-index: 3;
        background: linear-gradient(transparent 30%, rgba(0, 0, 0, 0.4));
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding:10px;

        .highlight-box{
            padding: 6px 12px ;
            background: var(--accent2);
            height: fit-content;
            width: fit-content;
            position: absolute;
            top: 5px;
            right: 5px;
            color: var(--white);

        }
        .bottom-heading{
            font-weight: 700;
            color: var(--white);
        }



    }

}