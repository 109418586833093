.hero {
  height: 90svh;
  background: var(--black);
  position: relative;
  .hero-overlay{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.288);
    position: absolute;
    top: 0;
    z-index: 18;

  }
  .swiper {
    width: 100%;
    height: 100%;

    .swiper-button-prev,
    .swiper-button-next {
      background: black;
      color: white;
      border-radius: 50%;
      padding: 10px;
      width: 40px;
      height: 40px;
      &::after {
        font-size: 15px;
        font-weight: 700;
      }
    }
  }

  .hero-slide {
    width: 100%;
    height: 100%;
    position: relative;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    justify-content:center ;
    align-items: center;
    .overlay {
      background: rgba(0, 0, 0, 0.494);
      z-index: 10;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }
    .slide-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 11;
      // top: 20%;
      gap: calc(var(--unit)/2);
     width: 70%;
    //  margin:auto;
      // left: 0;
      h3 {
        color: var(--white);
        font-size: 65px;
        line-height: 100%;
        text-align: center;
      }
      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 100%;
        text-align: center;
        color: var(--white);
      }
    }


  }
}
@media only screen and (max-width:1300px) {
  .slide-content{
    h3{
      font-size: 60px !important;
      line-height: 55px !important;
      font-weight: 700 !important;
    }
  }
  
}
@media only screen and (max-width:1050px) {
  .slide-content{
    h3{
      font-size: 45px !important;
      line-height: 45px !important;
    }
  }
  
}
@media only screen and (max-width:700px) {
  .slide-content{
    h3{
      font-size: 30px !important;
      line-height: 30px !important;
    }
  }
  
}
@media only screen and (max-width:700px) {
  .slide-content{
    h3{
      font-size: 30px !important;
      line-height: 30px !important;
    }
    P{
      line-height: 20px !important;
    }
  }
  
}
.pgbreak > *{
  z-index: 11;
}