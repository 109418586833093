.feature-section{
    padding: 70px 0px 40px 0px;

    // background: var(--grey);

    .feature-section-cont{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--gap);
        .left{
            aspect-ratio: 4/3;
            width: 50%;
            // border: 1px solid red;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            padding: 20px;
        }
        .right{
            height: 100%;
            width: 50%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            gap: calc(var(--gap)/1);

            h2{
                font-size: 3em;
                margin-top: -10px;
                color: var(--black);
            }
            p{
                margin: -5px 0px 5px 0px ;
            }
        }




    }

}



@media only screen and (max-width: 1200px) {
    .feature-section-cont{

       flex-direction: column !important;
       align-items: center !important;
       gap: var(--gap);

       .left{
        width: 600px !important;
        padding: 30px !important;
       }

       .right{
        width: 80% !important;
        align-items: center !important;

        h2{
            font-size: 2em !important;
        }
       }
    }

}
@media only screen and (max-width: 700px) {
    .feature-section-cont{

       flex-direction: column !important;
       align-items: center !important;
       gap: var(--gap);

       .left{
        width: 90% !important;
        padding: 10px !important;
       }

       .right{
        width: 95% !important;
        align-items: center !important;

        h2{
            font-size: 1.8em !important;
        }
       }
    }

}