.about-section-parent {
  background-color: var(--grey);
}

.our-mission-parent {
  height: 400px;
  // background-color: var(--grey);

  .our-mission-cont {
    padding: var(--unit);
    height: 100%;
    display: flex;

    .our-mission-left-content {
      width: 50%;

    //   .vision {
    //     width: 100%;
    //     height: 100%;
        
    //   }

      .mission {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--gap);
        padding: 20px 10px;
        justify-content: center;
        opacity: 0;
        animation: op1 0.5s ease-out forwards;
        transform: translateY(20px);


        h3{
            font-size: 35px;
            color: var(--accent);
        }

     


        @keyframes op1 {
            0%{
                opacity: 0;
                transform: translateY(20px);
            }

            100%{
                opacity: 1;
                transform: translateY(0px);

            }
        }
      }
    }

    .our-mission-right-slider {
      width: 50%;
      height: 100%;
      padding-left: 100px;
      position: relative;

      &::after{
        background: linear-gradient(90deg ,white ,transparent 90%);
        height: 100%;
        width: 100px;
        display: block;
        content: "";
        position: absolute;
        top: 0px;
        left: 100px;
        z-index: 10;
      }

      &::before{
        background: linear-gradient(90deg ,transparent 10% ,white);
        height: 100%;
        width: 100px;
        display: block;
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 10;
      }

      .mySwiper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          width: 250px;
          height: 100%;
          background-color: red;
          margin: 0px;
          // background-image: url("../assets/mission.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;

          &.mission{
            background-image: url("../assets/mission.webp");
          }
          &.vision{
            background-image: url("../assets/vision.webp")
          }

          .overlay {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.116), black);
            color: var(--accent);
            font-size: 25px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding: 20px 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .our-mission-parent {
    height: auto !important;
  }
  .ourmission {
    flex-direction: column !important;

    .our-mission-left {
      width: 100% !important;
      height: 300px !important;
    }

    .our-mission-right {
      width: 100% !important;
    }
  }
}


// .pintu {
//     background: red;
// }