.img-parent {
    width: 350px;
    background: red ;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
   
    img{
        width: 100%;
        // height: auto;
        // object-fit: cover;
        transition: var(--trans);
     
    }


    .overlay{
        top: 0px;
        left: 0px;
        position: absolute;
        height:  100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.295);
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
        transition: var(--trans);
    }



    &:hover{
        .overlay{
            display: flex;
        }

        // img{
 
        //     width: 370px !important;
        // }
    }

   
    
}