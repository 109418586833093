.pagetop{
 
    height: 440px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
    position: relative;

    .overlay{
        background:linear-gradient(0deg, rgba(0, 0, 0, 0.6), 20% , rgba(0, 0, 0, 0.4));
        height: 100%;
        width: 100%;
        position: absolute;

    }

    h1{
        font-size: 65px;
        color: var(--white);
        z-index: 10;
    }


}


@media only screen and (max-width: 768px) {

.pagetop{
    h1{
        font-size: 32px;
        text-align: center;
    }
}

}

