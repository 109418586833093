.section-indicator{
    display: flex;
    align-items: center;
    gap: calc(var(--gap)/3);

    .line{
        height: 2px;
        width: 70px;
        background: var(--accent);
        display: none !important;
    }

    span{
        font-weight: 700;
        text-transform: uppercase;
        color: var(--accent);
        font-size: 1em;
    }
}