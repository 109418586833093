.bfooter {
    background-color: var(--accent2);
    margin-top: 80px;

    .bfooter-cont {
        height: 400px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--gap);

        .left-side {
            width: 30%;
        
            margin-bottom: 130px;

            .b-f-img {
                background: url("../assets/gallery/gallery-7.jpg");
                width: 350px;
                height: 376px;
            }
        }

        .right-side {
            width: 70%;

            .contact-details {
                width: 100%;
                display: flex;
                gap: var(--gap);

                .b-box1 {
                    width: 300px;
                    padding: 0px 10px;
                    display: flex;
                    flex-direction: column;
                    gap: var(--gap);
                    border-right: 1px solid rgba(255, 255, 255, 0.25);
                }

                .b-box2 {
                    width: 300px;
                     padding: 0px 10px;
                    display: flex;
                    flex-direction: column;
                    gap: var(--gap);
                    border-right: 1px solid rgba(255, 255, 255, 0.25);
                }

                .b-box3 {
                    width: 300px;
                     padding: 0px 10px;
                    display: flex;
                    flex-direction: column;
                    gap: var(--gap);
                }

                a{
                    text-decoration: none;
                    font-weight: 600;
                    font-size: 16px;
                    color: var(--white);
                    line-height: 30px;
                }
                .mail-icons {
                    width: 50px;
                    height: 50px;

                    .contact-icons {
                        height: auto;
                        width: 100%;
                        vertical-align: top;
                        color: var(--white);
                    }
                }
            }
            .text-btn{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 30px;
                padding-top: 30px;
               border-top: 1px solid rgba(255, 255, 255, 0.25);

                .text-h3{
                    font-weight: 700;
                    font-size: 24px;
                    color: var(--white);
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .bfooter {
        padding-bottom: 130px !important;
        padding-top: 130px !important;

        .bfooter-cont {
            flex-direction: column !important;

            .left-side {
                width: 100% !important;

                .b-f-img {
                    width: 100% !important;
                    margin-bottom: -170px !important;
                }
            }

            .right-side {
                width: 100% !important;
                margin-bottom: 90px !important;

                .contact-details {
                  margin-top: 50px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 830px) {
    .bfooter {
        padding-bottom: 400px !important;
        padding-top: 160px !important;
    }

    .right-side {
        flex-direction: column !important;

        .top {
            width: 100% !important;
            flex-direction: column !important;
            border-bottom: none !important;

            .box1 {
                width: 100% !important;
                border-bottom: 1px solid rgba(255, 255, 255, 0.281) !important;
                border-right: none !important;
                padding: 20px !important;

                .email {
                    align-items: center !important;

                    .b-footer-p {
                        font-size: 14px !important;
                        text-align: center !important;
                    }
                }
            }

            .box2 {
                width: 100% !important;
                border-bottom: 1px solid rgba(255, 255, 255, 0.281);
                border-right: none !important;
                padding: 20px !important;

                .phone {
                    align-items: center !important;

                    .b-footer-p {
                        font-size: 14px !important;
                    }
                }
            }

            .box3 {
                width: 100% !important;
                border-bottom: 1px solid rgba(255, 255, 255, 0.281) !important;
                border-right: none !important;
                padding: 20px !important;

                .location {
                    align-items: center !important;

                    .b-footer-p {
                        align-items: center !important;
                        justify-content: center !important;
                        font-size: 14px !important;
                    }
                }
            }
        }

        .bottom {
            padding: 20px !important;
            margin-top: 120px !important;
            flex-direction: column !important;
            gap: var(--gap) !important;
            align-items: center !important;
            justify-content: center !important;

            .bottom-h3 {
                font-size: 20px !important;
                font-weight: 700 !important;
            }

            .btn {
                font-size: 14px !important;

            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .bfooter {
        // padding-bottom: 430px !important;
        padding-top: 160px !important;
    }
    .contact-details {
        width: 100% !important;
        flex-direction: column !important;
        .b-box1 {
            width: 100% !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.281) !important;
            border-right: none !important;
            align-items: center !important;
            padding-bottom: 10px !important;
        }
        .b-box2 {
            width: 100% !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.281) !important;
            border-right: none !important;
            align-items: center !important;
            padding-bottom: 10px !important;
        }
        .b-box3 {
            width: 100% !important;
            border-right: none !important;
            align-items: center !important;
            text-align: center !important;
        }
    }
    
    .bottom-h3 {
        padding: 20px !important;
        font-size: 14px !important;
        text-align: center;
    }

    .btn {
        font-size: 14px !important;
    }
    .text-btn{
       flex-direction: column !important;
       gap: var(--gap) !important;
        .text-h3{
            font-size: 18px !important;
        }
    }

    .b-f-img{
        margin-top: 80px !important;
        height: 300px !important;
     }
}