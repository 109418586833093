.blog-parent {
    margin: 50px 0px;
    background: url("../assets/book_now_wallpaper.webp");
    .blog-container {
      display: flex;
      gap: var(--gap);
  
      .blog-left {
        width: calc(100% / 3 * 2);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: var(--gap);
        height: 100%;
      }
  
      .blog-right {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        height: 100%;
  
        .text-recent {
          border: 1px solid var(--accent2);
          color: var(--accent2);
          font-size: 15px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 700;
          line-height: 1.2em;
          padding: 5px 20px;
          width: fit-content;
          margin: 25px auto;
        }
      }
    
    }

    
  }

  
  @media only screen and (max-width: 1119px) {
    .blog-container {
      flex-direction: column !important;
  
      .blog-left {
        width: 100% !important;
      }
  
      .blog-right {
        width: 100% !important;
        display: none !important;
      }
    }
  }