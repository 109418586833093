.book-now {
  // padding: 30px 0px;
  background-image: url("../assets/book_now_wallpaper.webp");
  // background-image: url("../assets/1979349-ai.webp");

  .book-now-cont {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
    gap: calc(var(--gap) * 2);
    .forms-div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: calc(var(--gap) * 2);
      background-color: var(--white);
      box-shadow: var(--shadow);
      padding: 0px var(--unit);
      --new-grey: rgba(128, 128, 128, 0.364);
      z-index: 1;
      border-radius: calc(var(--unit) / 2);
      .form-btn {
        cursor: pointer;
        height: 100%;
        width: fit-content;
        border: none;
        background: none;
        padding: var(--unit) 0px;
        transition: var(--trans);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        gap: calc(var(--unit) / 2);

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 3px;
          width: 100%;
          background-color: var(--accent2);
          display: none;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 50px;
            aspect-ratio: 1;
            filter: drop-shadow(3px 5px 0.5px rgba(128, 128, 128, 0.363));
          }
        }

        .tag {
          color: var(--black);
          text-align: center;
          max-width: 15ch;
          line-height: 120%;

          &.holiday-tag {
            max-width: 8ch;
          }

          &.active {
            font-size: 12px;
            font-weight: 700;
            color: var(--accent2);
          }
        }

        &.active {
          // background: var(--accent);
          // color: var(--white);
          // border-bottom: 3px solid var(--accent);

          &::after {
            display: block;
          }
        }
      }
    }

    // .form {
    //   width: 100%;
    //   height: auto;
    //   // margin-top: 100px;
    //   box-shadow: var(--shadow);
    //   padding: 20px;
    //   display: flex;
    //   border-radius: var(--rad);
    //   flex-direction: column;
    //   align-items: center;
    //   flex-wrap: wrap !important;
    //   gap: 20px;

    //   p {
    //     text-align: center;
    //     margin: 10px 0px;
    //   }

    //   form {
    //     --newgap: 20px;
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     gap: var(--newgap);
    //     padding: 20px;

    //     input,
    //     select,
    //     option,
    //     textarea {
    //       padding: 10px 20px !important;
    //       font-size: 18px !important;
    //       border: none !important;
    //       outline: none !important;
    //       width: 100% !important;
    //       border: 2px solid rgba(128, 128, 128, 0.186) !important;

    //       &:focus {
    //         border: 2px solid var(--accent);
    //       }
    //     }

    //     .form-line {
    //       display: flex;
    //       width: 100%;
    //       gap: var(--newgap);
    //       align-items: center;
    //       .form-increament-div {
    //         display: flex;
    //         width: 100%;

    //         .inc-num {
    //           width: 50px;
    //           height: 100%;
    //           display: flex;
    //           align-items: center;
    //           justify-content: center;
    //         }
    //       }
    //     }

    //     label {
    //       width: 100%;
    //       display: flex;
    //       align-items: flex-start;
    //       justify-content: center;
    //       flex-direction: column;
    //     }
    //   }
    // }
  }
}

.gap {
  width: 100%;
  height: 20px;
}

// form

.form {
  width: 100%;
  height: auto;
  background-color: var(--white);
  box-shadow: var(--shadow);
  border-radius: calc(var(--unit) / 2);
  transform: translateY(-25%);
  margin: 0px 10px;

  form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--unit);
    padding: calc(var(--unit) * 4) 20px;

    // ways option
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: calc(var(--unit) / 2);
        .trip1 {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: calc(var(--unit) / 3);
          padding: 0px 10px;
          border-radius: 25px;
          cursor: pointer;
          &.active {
            background-color: #0790be3d;

            .circle {
              background-color: var(--accent2);
              border: none;

              svg {
                color: var(--white);
              }
            }
          }

          .circle {
            width: 15px;
            aspect-ratio: 1;
            border-radius: 50%;
            border: 1px solid var(--svg-color);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }

    // middleform

    .middle-form {
      width: 100%;
      height: auto;
      border: 1px solid var(--svg-color);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: var(--unit);
      // overflow: hidden;

      // from destination

      .from {
        height: 100%;
        width: 100%;
        padding: 10px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        // gap: calc(var(--unit) / 2);
        border-right: 1px solid var(--svg-color);

        cursor: pointer;

        &:hover {
          background-color: #0790be3d;
        }

        &:last-child {
          border-right: none !important;
          border-radius: 0px var(--unit) var(--unit) 0px;
        }

        .top-tag {
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: calc(var(--unit) / 2);
          span {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        h3 {
          font-size: 30px;
          font-weight: 800;
          color: black;
          display: flex;
          flex-direction: column;
          padding-top: 10px;

          &.date {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            gap: calc(var(--unit) / 2);
            .month {
              font-size: 16px;
            }
          }
        }
        .place-tag {
          font-size: 14px;
          line-height: 110%;
        }
        &.to {
          width: 140%;
        }
        &.from-div {
          position: relative;
          border-radius: var(--unit) 0px 0px var(--unit);
          width: 140%;
          // midle return button

          .swipeIcon {
            position: absolute;
            right: -8%;
            top: 50%;
            z-index: 2;
            transform: translate(0, -50%);
            background-color: var(--white);
            box-shadow: var(--shadow);
            width: 40px;
            border-radius: 50%;
            aspect-ratio: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            color: var(--accent2);
          }
        }

        &.departure {
          position: relative;
          width: 80%;
          //  background: red;
          display: flex !important;
          flex-direction: column !important;
          //  align-items: center;
          justify-content: flex-start !important;
          gap: 10px;
          .tripInfo {
            font-size: 13px;
            text-align: left;
            line-height: 130%;
            font-weight: 600;
            color: var(--svg-color);
          }
        }

        // travellerclass

        &.travellersclass {
          position: relative;
          width: 140%;
          // traveller popup
          display: flex !important;
          flex-direction: column !important;
          justify-content: flex-start !important;
          // background: red;
          gap: 3px;
          .travle-class {
            position: absolute;
            right: 0;
            top: 40%;
            background-color: var(--white);
            box-shadow: var(--shadow);
            border-radius: calc(var(--unit) / 2);
            width: 630px;
            height: 300px;
            overflow-y: scroll;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(3, auto);

            &.rooms {
              width: 100%;
              grid-template-columns: 1fr;
              grid-template-rows: repeat(1, 1fr);
              align-items: center;
              justify-content: center;

              // hotel room css
              .adultsroom {
                padding: calc(var(--unit)) var(--unit);
                width: 100%;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;

                select {
                  padding: 10px;
                  border: 1px solid var(--accent);
                  background: var(--accent);
                  border-radius: 5px;
                  color: white;
                }
              }
            }

            .adults {
              grid-area: 1 / 1 / 2 / 4;
            }
            .children {
              grid-area: 2 / 1 / 3 / 3;
              // padding: calc(var(--unit)) calc(var(--unit)/2);
            }
            .infants {
              grid-area: 2 / 3 / 3 / 5;
              // padding: calc(var(--unit)) calc(var(--unit)/2);
            }
            .class {
              grid-area: 3 / 1 / 4 / 5;
            }

            .taps {
              padding: calc(var(--unit)) calc(var(--unit) / 1.2);
              display: flex;
              flex-direction: column;
              gap: calc(var(--unit) / 2);
              .top {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                p {
                  font-size: 14px;
                }
              }

              .taps-list {
                display: flex;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.37);

                .seat {
                  width: 35px;
                  aspect-ratio: 1;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  border: none;
                  background-color: var(--white);

                  &.active {
                    background-color: var(--accent2);
                    border: none;
                    color: white;
                    transform: scale(1.15);
                    box-shadow: var(--shadow);
                    border-radius: calc(var(--unit) / 3);
                  }
                }
              }
              // econmoyclass buttons
              .class {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left {
                  width: fit-content;
                  height: auto;
                  background-color: white;
                  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.37);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: calc(var(--unit) / 4);

                  .economybtn {
                    background-color: transparent;
                    border: none;
                    padding: calc(var(--unit) / 2);
                    font-size: 14px;

                    &.active {
                      transform: scale(1.07);
                      background-color: var(--accent2);
                      color: var(--white);
                      border-radius: calc(var(--unit) / 3);
                    }
                  }
                }

                .right {
                  .apply {
                    background-image: linear-gradient(
                      var(--accent),
                      var(--accent)
                    );
                    border: none;
                    padding: calc(var(--unit) / 2) calc(var(--unit));
                    border-radius: 25px;
                  }
                }
              }
            }
          }
        }

        &.priceNight {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          position: relative;

          .top-tag {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .price {
              .place-tag {
                justify-content: flex-start;
                display: flex;
              }
            }
          }
          .dropdown {
            // padding: 10px 0px;
            width: 100%;
            box-shadow: var(--shadow);
            top: 40%;
            right: 0;
            gap: 10px;
            display: flex;
            flex-direction: column;
            background: white;
            z-index: 10;
            position: absolute;
            border-radius: 10px;
            padding: 30px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

.autocomplete {
  position: absolute;
  left: -6%;
  top: 20%;
  z-index: 3;
}

.ant-select {
  height: calc(var(--unit) * 2);
}

.to-div {
  position: relative;
}

@media only screen and (max-width: 850px) {
  .book-now-cont {
    height: auto !important;
  }
  .autocomplete {
    width: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-select {
    width: 200px;
  }

  .forms-div {
    display: grid !important;
    grid-template-columns: repeat(3, auto);
    place-items: center;
    grid-row-gap: 5px !important;
  }

  .form {
    transform: translateY(-15%);
    form {
      .middle-form {
        width: 100%;
        flex-direction: column !important;

        .from {
          width: 100% !important;
          border-right: none !important;
          border-bottom: 1px solid var(--svg-color);

          &.from-div {
            border-radius: var(--unit) var(--unit) 0px 0px !important;
            .swipeIcon {
              right: 10%;
              top: 80%;
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .travle-class {
    width: 100% !important;
  }

  .children {
    grid-area: 2/1/3/5 !important;
  }
  .infants {
    grid-area: 3/1/4/5 !important;
  }
  .class {
    grid-area: 4/1/5/5 !important;
    .classes {
      flex-direction: column;
      align-items: flex-start !important;
      gap: calc(var(--unit)) !important;
    }
  }
}

.stayoption {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  .tap-new {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.37);
    font-size: 16px;
    border: none;
    padding: 5px 15px;
    background: white;
    &.active 
    {
      background: var(--accent2) !important;
      color: white;
    }
  }
}

.first-bar {
  width: 100%;
}

.tag-head {
  font-size: 12px;
  font-weight: 700;
  padding: 5px 0px;

 
}


.holiday-wrapper 
{
  // border: 1px solid red;
  width: 100%;
  h4,p 
  {
    padding: 0px !important;
    font-weight: 700 !important;

    span 
    {
      font-size: 14px !important;
      font-weight: 700 !important;
    }
  }
}

.dep 
{
// border: 1px solid red;
}

.depDate 
{
  padding:10px 5px !important;
}
.room-guest 
{
  padding:10px 5px !important;

}