.four-zero-parent{
    height: 100vh;
    width: 100%;
    // background: ;
    background-image: linear-gradient(#0c0c0cbd, #0c0c0cb7),
    url("../../assets/fourzero.webp");


    .four-zero-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // gap:10px;
        .four-zero-heading{
            text-align: center;
            font-size: 80px;
            font-weight: 800;
            letter-spacing: 0.4;
            color: var(--accent);
            line-height: 120%;
            padding-bottom: 5px;
        }
        .four-zero-subheading{

            text-align: center;
            font-size: 50px;
            font-weight: 900;
            letter-spacing: 1px;
            line-height: 120%;
            color: white;  
        }
        .yes-text{
            font-weight: 500 !important;
            opacity: 0.8;
        }
        .four-p{
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0.8;
            color: white;
            line-height: 120%;   
            margin-bottom: 35px;
            font-style: italic;
        }

    }
}

@media only screen and (max-width:500px){
    .four-zero-parent{
            
        .four-zero-container{
          
            .four-zero-heading{
              font-size: 50px; 
            }
            .four-zero-subheading{
    font-size: 30px;
                
            }
            .yes-text{
               
            }
            .four-p{
               font-size: 18px;
            }
    
        }
    }

}