.gallery-grid-parent{
    padding: 50px 0px ;
 
    .gallery-grid-cont{

flex-wrap: wrap;
        display: flex;
        gap: 20px;
 
        justify-content: center !important;

        .row{
            width: 350px;
            height: 100%;

            display: flex;
            flex-direction: column;
            gap: 20px;
            flex-wrap: wrap;
           
        }





    }
}
.load-more{
    display:flex;
    margin:auto;
    align-items: center;
    width:fit-content;
    // margin-bottom: 30px;
    margin-top: 20px;
    justify-content: center;
}



@media only screen and (max-width: 600px){
    .gallery-grid-cont{
        flex-direction: column !important;
        align-items: center !important;
    }
}