
    .tag {
      font-size: 12px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

 




.progress-bar {
    padding: 0px calc(var(--unit)/2);
    width: 100%;
    position: relative;
    overflow: hidden;
 

    .bar{
    
        &:hover{
            background-color: none !important;
        }
    }
  }
  .taps {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: calc(var(--unit) / 2);
    flex-wrap: wrap;

   .tapone{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    box-shadow: var(--shadow);
    padding: calc(var(--unit)/3);

    span{
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .greaterIcon{
      transform: rotate(180deg);
    }
   }
  }