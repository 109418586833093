.popup-parent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.popup-cont {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  max-width: 100%;
  margin: 0px 5px !important;
  position: relative;
}

.close-pop {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 30px;
  color: var(--darkred);
  border: 2px solid var(--darkred);
  padding: 5px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-heading {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--darkred);
}

.input-div {
  margin-bottom: 15px;
  width: 100%;
}

.input-div input,
.input-div textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
.input-div input:focus,
.input-div textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.input-div textarea {
  resize: none;
}

.btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn {
  //   background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn:hover {
  //   background-color: #0056b3;
}
