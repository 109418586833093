.form {
  form {
    .bottom-form {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 1fr;
      border-radius: var(--unit);
      background-color: white;
      border: 1px solid var(--svg-color);
      .input {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: var(--unit);
        padding: calc(var(--unit) / 2) var(--unit);
        border-right: 1px solid var(--svg-color);
        cursor: pointer;
        p {
          font-size: 14px;
        }
        &:last-child {
          border: none;
        }

        .city {
          h4 {
            font-size: 30px;
          }
        }

        &.fromcity {
          grid-area: 1 / 1 / 2 / 3;
          position: relative;

          .autocomplete {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.tocity {
          grid-area: 1 / 3 / 2 / 5;
          position: relative;
          .autocomplete {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.depDate {
          grid-area: 1 / 5 / 2 / 6;
          gap: calc(var(--unit) / 2);
          position: relative;
          .dep {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // gap: calc(var(--unit) / 2);
            p {
              font-size: 14px;
            }

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              transition: var(--trans);
              &.rotateIcon {
                transform: rotate(180deg);
              }
            }
          }
        }
        &.room-guest {
          grid-area: 1 / 6 / 2 / 7;
          position: relative;
          gap: calc(var(--unit) / 2);
          // padding-right: 0;
          .dep {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            // gap: calc(var(--unit) / 2);
            p {
              font-size: 14px;
              text-align: left;
            }

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              transition: var(--trans);
              &.rotateIcon {
                transform: rotate(180deg);
              }
            }
          }

          .room-popup {
            position: absolute;
            top: 30%;
            right: 0;
            height: 250px;
            width: 400px;
            overflow-y: scroll;
            z-index: 2;
            background-color: var(--white);
            border-radius: calc(var(--unit) / 2);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.411);
            padding: var(--unit);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: var(--unit);

            .top {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .remove {
                color: var(--accent2);
                font-size: 14px;
              }
            }
            .middle {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              gap: calc(var(--unit));
              border-bottom: 1px solid var(--svg-color);
              padding-bottom: 20px;
             
              .left {
                width: 50%;
                p {
                  text-align: left;
                  font-size: 10px;

                  strong {
                    font-size: 12px;
                    font-weight: 700;
                  }
                }
                .room-numbers {
                  width: 70%;
                  display: flex;
                  justify-content: space-between;
                  padding: calc(var(--unit) / 2);
                  align-items: center;
                  // gap: var(--unit);
                  background-color: var(--white);
                  box-shadow: var(--shadow);
                 
                  p {
                    font-size: 20px;
                  }
                  span {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                      font-size: 20px;
                    }
                  }
                }
              }
            }

            .bottom {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              
              .add-another-room {
                background-color: var(--white);
                border: 1px solid var(--accent2);
                padding: calc(var(--unit) / 2);
                border-radius: calc(var(--unit) / 2);
              }

            
            }
          }
        }
        &.filters {
          grid-area: 1 / 7 / 2 / 8;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: calc(var(--unit) / 2);
          position: relative;
          .dep {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            // gap: calc(var(--unit) / 2);
            p {
              font-size: 14px;
            }

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              transition: var(--trans);
              &.rotateIcon {
                transform: rotate(180deg);
              }
            }
          }

          .filter-popup {
            position: absolute;
            right: 0;
            top: 30%;
            width: 400px;
            height: 300px;
            background-color: var(--white);
            box-shadow: var(--shadow);
            .top {
              height: 80%;
              width: 100%;
              overflow-y: scroll;
              border-bottom: 1px solid var(--svg-color);
              padding: var(--unit);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: calc(var(--unit) / 2);

              .second-bar {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: calc(var(--unit) / 2);

                .tag {
                  font-size: 12px;
                  font-weight: 700;
                }

                .taps {
                  display: flex;
                  width: 100%;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: calc(var(--unit) / 3);
                  .tap1 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: var(--white);
                    padding: calc(var(--unit) / 3);
                    box-shadow: var(--shadow);
                    font-size: 12px;
                    gap: calc(var(--unit) / 4);

                    &.select {
                      background-color: var(--accent2);
                      color: var(--white);
                    }

                    span {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                }
              }
            }
            .bottom {
              height: 20%;
              width: 100%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding: 0px 10px;
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 850px){
  .form{

    form{
      padding-top: 110px;
      .bottom-form{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr) !important;

        .input{
          width: 100%;
          border-right: none !important;
        border-bottom: 1px solid var(--svg-color);
          &.fromcity{
            grid-area: 1 / 1 / 2 / 2;
          }

          &.tocity{
            grid-area: 2 / 1 / 3 / 2;
          }
          &.depDate{
            grid-area: 3 / 1 / 4 / 2;
          }
          &.room-guest{
            grid-area: 4 / 1 / 5 / 2;

           
          }
        &.filters{
          grid-area: 5 / 1 / 6 / 2;


        }
        }
      }
    }
  }
}


@media only screen and (max-width: 450px){
  .room-popup{
    width: 100% !important;
  }

  .filter-popup{
    width: 100% !important;
  }
}