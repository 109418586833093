// form css
// .form-parent {
//   margin-top: -100px;
//   position: absolute;
//   z-index: 14;

//   .form-container {
//     padding: 40px 0px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: var(--gap);
//     background: var(--white);
//     box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
//     form {
//       width: 90%;
//       display: flex;
//       gap: calc(var(--gap) / 2);
//       flex-direction: row;
//       border: none;
//       align-items: flex-end;

//       .form-input {
//         width: 100%;
//         display: flex;
//         flex-direction: column;
//         gap: calc(var(--gap) / 2);
//         input {
//           padding: 10px 20px;
//           border: none;
//           outline: none;
//           border: 1px solid transparent;
//           transition: var(--trans);
//           background: rgb(245, 245, 245);
//           width: 100%;


//           &.date-input{
//             color:  rgba(170, 165, 165, 0.452);
//           }
//           &:focus {
//             border: 1px solid var(--accent);
//           }
//           &::placeholder {
//             color: rgba(170, 165, 165, 0.452);
//           }
//         }
//       }
//     }
//   }
// }
@media only screen and (max-width: 700px) {
  // form {
  //   // width: 100% !important;
  //   // padding: 0px 20px !important;
  //   // flex-direction: column !important;
  //   // align-items: flex-start !important;
  //   display: none !important;
  // }
  .form-parent {
    display: none !important;
  }
}

.home-service-section {
  padding: 50px 0px;
  background: var(--grey);


  .service-section-home-cont {
    .mySwiper {
      width: 100%;
      height: 100%;

      .swiper-button-prev,
      .swiper-button-next {
        background: rgba(184, 182, 182, 0.77);
        color: var(--accent);
        border-radius: 50%;
        
        padding: 10px;
        width: 30px;
        height: 30px;
        &::after {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

   
    .swiper-slide {
      height: 100%;
      width: 100%;
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      
      .home-card-box {
        height: 100%;
        display: flex;
        width: 350px;
        gap: var(--gap);
        background: var(--white);
        flex-direction: column;
        .img-box {
          width: 100%;
          height: 250px;
          position: relative;
          .icon {
            position: absolute;
            top: 10px;
            right: 0;
            background: var(--accent);
            padding: 5px;
            color: var(--white);
            font-weight: 700;
          }
        }
        .img1 {
          background: url("../assets/service/theme.jpg");
        }
        .img2 {
          background: url("../assets/about.webp");
        }
        .img3 {
          background: url("../assets/service/visa.jpg");
        }
        .img4 {
          background: url("../assets/service/hotels.jpg");
        }
        .img5 {
          background: url("../assets/service/travel_insurance.jpg");
        }
        .img6 {
          background: url("../assets/service/sim.jpg");
        }
        .card-content {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 0px 10px 20px 10px;
          h3 {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
          }
        }
      }
      .btns {
        display: flex;
        text-align: center;
        justify-content: space-between;
        border-top: 1px solid #a5a5a560;
        width: 350px;
        .btn1 {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          border-right: 1px solid #a5a5a560;
          width: 50%;
          padding: 10px;
          color: var(--text);
          font-size: 16px;
          font-weight: 500;
          svg {
            padding-top: 2px;
            color: var(--accent);
          }
          &:hover {
            color: var(--accent2);
            svg {
              padding-top: 2px;
              color: var(--accent2);
            }
          }
        }
        .btn2 {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--black);
          gap: 5px;
          font-size: 16px;
          padding: 10px;
          text-wrap: nowrap;
          font-weight: 500;
          svg {
            padding-top: 2px;
            color: var(--accent);
          }
          &:hover {
            color: var(--accent2);
            svg {
              padding-top: 2px;
              color: var(--accent2);
            }
          }
        }
      }
    }
    .swiper-pagination-bullet {
      background: var(--accent2);
      font-size: 30px;
      height: 15px;
      width: 15px;
    }
  }
  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap);
    h2 {
      font-size: 3em;
      color: var(--black);
      padding: 0px 0px 30px 0px;
    }
    @media only screen and (max-width: 1200px) {
      h2 {
        font-size: 2em !important;
      }
    }
    @media only screen and (max-width: 700px) {
      h2 {
        font-size: 1.8em !important;
      }
    }
  }
}
