.img-card-parent {
  height: auto;
  background-color: white;

  .img-card-cont {
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    gap: 10px;
    flex-wrap: wrap;

    .top-cont {
      display: flex;
      gap: 10px;
      height: 600px;
      width: 100%;

      .img-first {
        display: flex;
        gap: 10px;
        height: 100%;
        width: 60%;

        .img-hor {
          width: 100%;
          height: 100%;
          background-color: white;
          box-shadow: var(--shadow);
          padding: 10px 10px;

          .photo {
            width: 100%;
            height: 100%;
            background-image: url("../assets/gallery/gallery-3.jpg");
            position: relative;
            z-index: 5;

            .overlay {
              background: rgba(0, 0, 0, 0.329);
              z-index: -5;
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
            }
            .tag {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 20px 20px;

              .name {
                display: flex;
                align-items: center;
                gap: 5px;

                span {
                  height: 3px;
                  width: 30px;
                  background-color: white;
                }
                h2 {
                  color: white;
                  font-size: 22px !important;
                }
              }
            }

            &.study-photo {
              background-image: url("../assets/service/study2.webp");
            }

            &.sport-journey {
              background-image: url("../assets/service/sport1.webp");
            }

            &.acro-yoga-journey{
                background-image: url("../assets/service/acroyoga1.png");
            }
            &.event-journey{
                background-image: url("../assets/gallery/gallery_6.webp");
            }
          }
        }
      }

      .img-third {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 40%;
        height: 100%;

        .img-ver {
          width: 100%;
          height: 50%;
          background-color: white;
          padding: 10px 10px;
          box-shadow: var(--shadow);

          .photo {
            width: 100%;
            height: 100%;
            background-image: url("../assets/gallery/gallery-3.jpg");
            position: relative;
            z-index: 5;

            &.culinary-jorney {
              background-image: url("../assets/service/cul1.png");
            }
            &.his-cul-journey {
              background-image: url("../assets/service/his1.webp");
            }

            &.photo-journey {
              background-image: url("../assets/service/photo1.webp");
            }

            &.yoga-medi-journey {
              background-image: url("../assets/service/yoga1.png");
            }

            .overlay {
              background: rgba(0, 0, 0, 0.329);
              z-index: -5;
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
            }
            .tag {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 20px 20px;

              .name {
                display: flex;
                align-items: center;
                gap: 5px;

                span {
                  height: 3px;
                  width: 30px;
                  background-color: white;
                }
                h2 {
                  color: white;
                  font-size: 22px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .top-cont {
    flex-direction: column !important;
    height: 1200px !important;

    .img-first {
      width: 100% !important;
      height: 800px !important;
      flex-direction: column !important;

      .img-hor {
        width: 100% !important;
        height: 50% !important;

        .name{
            h2{
                font-size: 16px !important;
            }
        }
      }

      .img-ver {
        width: 100% !important;
        height: 50% !important;
      }
    }

    .img-third {
      width: 100% !important;
      height: 400px !important;
    }
  }
}



