.theme-based-form {



    .theme-based-form22 {


        .theme-based-middle-form {


            .theme-based-from {
                position: relative;
                border-radius: var(--unit) 0px 0px var(--unit);
                width: 140% ;

                .theme-based-dropdown {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    left: 10%;
                    top: 70%;
                    z-index: 2;
                    background-color: var(--white);
                    box-shadow: var(--shadow);
                    width: auto;
                    border-radius:calc(var(--unit)/2);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    color: var(--black);
                    text-align: center;
                    padding: 16px 10px;
                    gap: calc(var(--unit)/2);
                    height: 300px;
                    overflow-y: scroll;
                  

                    .list-hover {
                        width: 100%;
                        // height: 28px;
                        border-radius: calc(var(--unit)/2);
                        padding: 8px ;

                        &:hover {
                            background-color: var(--svg-color);
                            color: var(--white);

                        }

                    }


                }
            }
        }
    }
}

@media only screen and (max-width: 850px){
    .theme-based-from {
        width: 100%;
    }
}