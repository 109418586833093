.blog-post {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);
   
  
    .left-right {
      width: 350px;
      padding: 0px 10px;
      display: flex;
      gap: var(--gap);
      padding: 10px;
      border-bottom: 1px solid rgba(126, 126, 126, 0.397);
      margin-bottom: 15px;
  
      .left {
        background-image: url("../assets/gallery/g-1.jpg");
        width: 100px;
        height: 66px;
      }
  
      .right {
        display: flex;
        flex-direction: column;
        gap: calc(var(--gap) / 2);
        width: 350px;
  
        .post-h5 {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          cursor: pointer;
  text-decoration: none;
          a {
            text-decoration: none;
            color: var(--black);
          }
  
          :hover {
            color: var(--accent2);
          }
        }
  
        .date-comments {
          display: flex;
          gap: var(--gap);
  
          a {
            text-decoration: none;
            color: #8d78a2;
          }
  
          :hover {
            color: var(--accent2);
          }
  
          .date {
            font-weight: 400;
            font-size: 14px;
            border-right: 1px solid rgba(126, 126, 126, 0.397);
            padding: 0px 10px 0px 0px;
          }
  
          .comments {
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }
  }
  
  @media only screen and (max-width:1119px){
  
      .blog-post {
          width: 100% !important;
    
      .left-right {
          width: 100% !important; 
      }
  }
  }