.service-card {
  padding: 30px 0px;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;

  .card-box {
    width: 48%;
    gap: 20px;
    padding: 40px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    //   background: rgb(236, 236, 234);
    display: flex;
    flex-direction: column;

    .card-content {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .card-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 1.1em;
      }
    }

    .card-image {
      // background: url("https://img.freepik.com/free-photo/front-view-travel-agent-her-working-place-map-indoors-manager-operator-agency-world-document-occupation-worker_140725-155650.jpg?uid=P100451978&ga=GA1.1.473352914.1724306830&semt=ais_hybrid");
      width: 100%;
      height: 300px;
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .card-box:nth-child(1),
  .card-box:nth-child(3),
  .card-box:nth-child(5) {
    margin-bottom: 30px;
  }

  .card-box:nth-child(2),
  .card-box:nth-child(4),
  .card-box:nth-child(6) {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 770px) {
  .service-card {
    .card-box {
      width: 100% !important;
      padding: 20px;
    }

    .card-box:nth-child(1),
    .card-box:nth-child(3),
    .card-box:nth-child(5) {
      margin-bottom: 0px;
    }

    .card-box:nth-child(2),
    .card-box:nth-child(4),
    .card-box:nth-child(6) {
      margin-top: 0px;
    }
  }
}