.contact-parent {
    margin: 50px 0px;

    .contact-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--gap);

        .contact-left {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: var(--gap);

            .conatct-heading {
                font-size: 48px;
                line-height: 52px;
                color: var(--black);
            }

            .contact-para {
                font-weight: 400;
                font-size: 16px;
                color: var(--text);
                line-height: 25px;
            }

            .contact-form {
                display: flex;
                flex-direction: column;
                gap: calc(var(--gap)/2);
                padding: 20px 0px;

                input,
                textarea {
                    width: 100%;
                    padding: 15px;
                    background-color: #ffffff;
                    border: 1px solid #d9d9d9;

                    &::placeholder {
                        color: rgba(128, 127, 127, 0.938);
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }

        .contact-right {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: var(--gap);
            padding-left: 30px;

            .c-r-h3 {
                font-weight: 700;
                font-size: 26px;
                line-height: 28px;
                color: var(--black);
            }

            .c-r-para {
                font-weight: 500;
                font-size: 16px;
                color: var(--text);
                line-height: 25px;
                text-align: left;
            }

            .details-list {
                display: flex;
                flex-direction: column;
                gap: calc(var(--gap));
                width: 450px;
                padding: 30px 0px;

               .details-cont{
                display: flex;
                flex-direction: column;
                gap: calc(var(--gap));

                .location {
                    display: flex;
                    gap: var(--gap);
                    width: 100%;
                }

                .email {
                    display: flex;
                    gap: var(--gap);
                    width: 100%;
                }

                .phone {
                    display: flex;
                    gap: var(--gap);
                    width: 100%;
                    
                }

                .c-icon {
                    // background-color: #0791BE;
                    // border: 1px solid #F56960;
                    color: #F56960;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 25px;
                    height: 35px;                    
                    text-align: center;
                   aspect-ratio: 1;
                }

                .location-right {
                    display: flex;
                    flex-direction: column;
                    gap: calc(var(--gap)/2);

                    .l-top {
                        a {
                            text-decoration: none;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 22px;
                            color: var(--black);
                        }
                    }

                    .l-bottom {
                        a {
                            text-decoration: none;
                            font-weight: 400;
                            font-size: 16px;
                            color: var(--black);
                            line-height: 24px;
                        }
                    }

                }
               }
            }

            .contact-social-links {
                display: flex;
                flex-direction: column;
                gap: var(--gap);

                .media-text {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28px;
                    color: var(--black);
                }

                .social-icons {
                    display: flex;
                    gap: var(--gap);

                    .media-icons {
                        border: 1px solid #F56960;
                        display: flex;
                        font-size: 20px;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        width: 40px;

                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            color: #F56960;
                        }
                    }
                    :hover {
                        color: var(--accent2);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:800px) {

    .contact-cont {
        flex-direction: column !important;

        .contact-left {
            width: 100% !important;

            .conatct-heading {
                font-size: 30px !important;
            }

            .contact-para {
                font-size: 14px !important;
            }

        }

        .contact-right {
            width: 100% !important;

            .c-r-h3 {
                font-size: 18px !important;
            }

            .media-text {
                font-size: 18px !important;
            }

            .l-top {
                a {
                    font-size: 14px !important;
                }
            }
        }
    }
}