.img-swiper-parent {
    padding: 50px 10px;
    background: var(--grey);

    .img-swiper-cont {
        padding: 20px;


        .img-swiper-hading {
            width: 100%;
            text-align: center;
            font-size: 28px;
            color: var(--black);
            padding: 0px 0px 40px 0px;
        }

        .mySwiper {
            height: 100%;
            width: 100%;
            margin: auto;
            padding-bottom: 40px;

            .swiper-button-prev,
            .swiper-button-next {
                background: rgba(0, 0, 0, 0.815);
                color: white;
                border-radius: 50%;
                padding: 10px;
                width: 40px;
                height: 40px;

                &::after {
                    font-size: 15px;
                    font-weight: 700;
                }
            }
        }

        .img-card {
            width: 365px;
            // margin: 15px;
            margin: auto;
            background: var(--white);
            padding: var(--pad);
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.092);;
            border: 12px solid white;

            .img-title {
                font-size: 22px;
                font-weight: 600;
                text-align: center;
                padding: 20px 0px;
                background: var(--white);
            }

            .img-box {
                width: 100%;
                height: 250px;
            }

            .img1 {
                background: url('../assets/imgswiper/simg1.jpg');
            }

            .img2 {
                background: url('../assets/imgswiper/simg2.jpg');
            }

            .img3 {
                background: url('../assets/imgswiper/simg3.jpg');
            }

            .img4 {
                background: url('../assets/imgswiper/simg4.jpg');
            }

            .img5 {
                background: url('../assets/imgswiper/simg5.jpg');
            }

            .img6 {
                background: url('../assets/imgswiper/simg6.jpg');
            }

        }
    }

}

@media only screen and (max-width:500px) {
    .img-swiper-parent {
        .img-swiper-cont {
            .img-swiper-hading {
                font-size: 20px;
            }
        }
    }
}

@media only screen and (max-width:900px) {
    .img-swiper-parent {
        .img-swiper-cont {
            .img-swiper-hading {
                font-size: 1.6rem;
            }
        }
    }
}

@media only screen and (max-width:1000px) {
    .img-swiper-parent {
        .img-swiper-cont {
            .img-swiper-hading {
                font-size: 1.8rem;
            }
        }
    }
}