.culinary-parent {
  height: auto;

  .culinory-cont {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    padding: 30px 10px;
    .top-culinory {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--unit);

      h2{
        font-size: 25px;
            color: var(--black);
      }
    }

    .bottom-culinory {
      display: flex;
      gap: var(--gap);
      padding: 20px 10px;

      .culinory-left {
        width: 40%;

        .img-cont {
          // padding: 20px;

          .overlay {
            width: 100%;
            height: 100%;
            background-image: url("../assets/service/cul1.png");
          }
        }
      }

      .culinary-right {
        display: flex;
        flex-direction: column;
        width: 60%;
        gap: var(--gap);

        .cil-heading{
          // font-size: 25px;
          color: var(--black);
        }

        li {
          list-style-type: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: calc(var(--gap)/2);
          font-size: 16px;

          span {
            font-size: 25px;
            color: var(--accent);
            display: flex;
            justify-content:center ;
            align-items: center;
          }
        }
      }
    }
  }
}
