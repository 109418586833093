.pagebreak{
    background:url("../assets/SVG/tear.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    // border:1px solid red ;
    position: relative;
    margin-top: -50px;
  


}
.greypagebreak{
    
    background:url("../assets/SVG/teargrey-svg.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 105px;
    // border:1px solid red ;
    position: relative;
    margin-top: -55px;
}

