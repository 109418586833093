:root {
  --black: #101f46;
  --white: #ffffff;
  --accent: #f56960;
  --accent2: #0791be;
  --text: hsl(0, 0%, 22%);
  --gap: 20px;
  --unit:20px;
  --grey: #f8f8f8;
  --max-width: 1200px;
  --trans: all 0.4s ease-in-out;
  --shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  --rad: 10px;
  --scrollbar-thumb-color: var(--accent);
  --scrollbar-track-color: #525252;
  --loading-grey: #ededed;
  --svg-color:#999;
  --footer-bg:rgb(34, 34, 34);
  --footer-lst:rgb(39, 39, 39);
  // --loading-grey: #0f8dba;
}

*::before,
*::after,
* {
  box-sizing: border-box;
  // border: 1px solid red;
  margin: 0px;
  // font-family: "Raleway", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight:400;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


.heading,h1,h2,h3,h4,h5,h6{
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight:900;
  font-style: normal;
}


@media only screen and (max-width: 600px) {
  .heading,h1,h2,h3,h4,h5,h6{
   text-align: center !important;
  }
  
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

/* For Firefox */
body {
  scrollbar-width: thin;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}

body::-webkit-scrollbar-thumb {
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}

body::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

body {
  width: 100%;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}

p {
  text-align: justify;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  font-weight: 400;
  color: var(--text);
}

.bg-img-cover {
  background-position: left center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.bg-img-contain {
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.parent {
  width: 100%;

  .cont {
    max-width: var(--max-width);
    margin: auto;
    padding: 0px 10px;
    height: 100%;
    // background: rgba(102, 51, 153, 0.073);
  }
}

.btn {
  cursor: pointer;
  border: none;
  background: var(--accent);
  color: var(--white) !important;
  font-size: 18px;
  padding: 10px 20px;
  text-decoration: none;
  transition: var(--trans);
  width: fit-content;

  &:hover {
    background: var(--accent2);
  }
}
a{
  text-decoration: none;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  background:var(--accent2);
 width: 15px;
 height: 15px;
}


.calender{
  height: auto;
  background-color: white;
  box-shadow: var(--shadow);
  border-radius: calc(var(--unit)/2);
  position: absolute;
  top: 30%;
  left: -20%;
  z-index: 99999;
}
// .btn-div-insu{
//   align-items: center;
//   justify-content: center;
//   display: flex;
// }

@media only screen and (max-width: 850px){
  .calender{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}




.home-button 
{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.align-left 
{
  text-align: left !important;
}

.bg-cover 
{
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}