.swiper-card-parent {
  background-color: var(--grey);

  .swiper-cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding:30px 0px;     
    gap: var(--gap);


    .event-swiper {
      width: 100%;
     height: auto;
    }

    .swiper-card {
      height: 100%;
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 20px;
      gap: 20px;
      
     

     .swiper-left{
     height: 350px;
      width: 40%;
     } 

    .swiper-right{
      height: 100%;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: calc(var(--unit)/2);
      .swiper-title {
        color: var(--accent);
        font-size: 22px;
        font-weight: 700;
        
      }

      .s-card-text {
        font-size: 16px;
        font-weight: 400;
        color: var(--text);
        line-height: 25px;
        // padding: 0px 20px;
        text-align: justify;
      }
    }
    }
    .swiper-button-prev, .swiper-button-next{
        background: var(--black);
        color: white;
       --swiper-navigation-size:20px;
       padding: 20px;
       border-radius: 50%;
       
    }
  }
}


@media (max-width : 700px) 
{
  .event-swiper
  {
    height: auto !important;

.swiper-card 
{
  flex-direction: column !important;
.swiper-left 
{
  width: 100% !important;
}
.swiper-right 
{
 
  width: 100% !important;
}
  
}
  }
}


.event-swiper 
{
.swiper-button-next 
{
  display: none !important;
}
.swiper-button-prev 
{
  display: none !important;
}
}

