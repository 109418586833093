.blog-img-parent {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;

  .overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.1)
    );
    display: flex;
    justify-content: center;
    align-items: center;

    .blog-heading {
      width: var(--max-width);
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      color: #fff;
      font-weight: 700;
      font-size: 32px;
      padding: 60px 20px;
      line-height: 38px;
      text-align: center;
      gap: 10px;

      .category {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .category-tag {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          border-radius: 20px;
          font-size: 14px;
          background-color: var(--black);
          color: #fff;
          transition: background 0.3s ease-in-out;
          cursor: pointer;
          &:hover {
            background-color: var(--black);
          }
        }
      }
    }
  }
}

.blog-page-parent {
  background: url("../assets/book_now_wallpaper.webp");
  position: relative;
  .blog-parent-overlay 
  {
    background: rgba(247, 246, 246, 0.137);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    left: 0;
  }
  .blog-page-cont {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .blog-new-image {
      height: 400px;
      width: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }

    .para-div {
      .my-para {
        font-size: 18px;
        line-height: 1.6;
        color: #333;
        text-align: left;
        // padding: 10px;
        // border-left: 4px solid var(--black);
        margin-top: 20px;
        p 
        {
          text-align: left;
          
        }
      }
    }
  }
}

// Media queries for responsiveness
@media only screen and (max-width: 768px) {
  .blog-heading {
    font-size: 24px;
    padding: 40px 10px;
  }

  .blog-new-image {
    height: 300px;
  }
}
.blog-headings 
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;

  .inside-tab 
  {
    background: var(--black);
    color: var(--white);
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 14px;
  }
}