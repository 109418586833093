.footer-parent {
  height: 100vh;
  background: url("../assets/footer-img.webp");
  background-position: center;
  background-size: cover;
  position: relative;


  .footer-container {
    padding: 30px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: 100px;

    .footer-top-section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      gap: 20px;

      .footer-top-heading {
        font-size: 36px;
        font-weight: 800;
        color: var(--white);
        letter-spacing: 0.4;
        line-height: 120%;

      }
    }

    .footer-content-box {
      width: 100%;
      display: flex;
      gap: calc(var(--gap)*2);
      // padding-left: 30px;

      .footer-pages {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        h3 {
          font-size: 18px;
          border-left: 3px solid var(--accent2);
          padding: 0px 0px 0px 10px;
          font-weight: 500;
          color: var(--white);
        }

        p {
          font-size: 14px;
          color: var(--white);
          text-align: left;

        }

        .logo-ti {
          // background:url("../assets/logo.svg");
          width: 100%;
          display: flex;

          img {
            height: 70px;
          }
        }
      }

      .footer-services {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 10px;

        p {
          border-left: 3px solid var(--accent2);
          padding: 0px 0px 0px 10px;
          font-size: 18px;
          font-weight: 500;
          color: var(--white);

        }

        .navs {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;

          a {
            text-decoration: none;
            font-size: 14px;
            color: var(--white);

            &:hover {
              color: var(--accent2);
            }
          }
        }
      }

      .footer-legal {
        width: 20%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        p {
          border-left: 3px solid var(--accent2);
          padding: 0px 0px 0px 10px;
          font-size: 18px;
          font-weight: 500;
          color: var(--white);
        }

        a {
          text-decoration: none;
          font-size: 14px;
          color: var(--white);

          &:hover {
            color: var(--accent2);
          }
        }
      }

      .footer-contact {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .footer-phone,
        .footer-mail,
        .footer-add {
          width: 100%;
          display: flex;
          align-items: flex-start !important;
          gap: 20px;

          .footer-icon-glass {
            //   background: rgba(163, 163, 163, 0.26);
            align-items: flex-start !important;
            border-radius: 50%;
            color: var(--accent);
            display: flex;
            font-size: 20px;
            height: 35px;
            justify-content: flex-start;
            padding: 8px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 300;
          color: var(--white);
          text-align: left !important;
        }

        a {
          text-decoration: none;
          color: var(--white);
          font-size: 16px;

          &:hover {
            color: var(--accent2);
          }
        }
      }
    }
  }
}

.footer-bottom-content-box {
  background: rgba(0, 0, 0, 0.979);

  height: auto;

  .footer-bottom-content-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;

    .footer-social-icons {
      display: flex;
      gap: 30px;

      a {
        font-size: 24px;
        text-decoration: none;
        color: var(--accent);

        &:hover {
          color: var(--accent2);
        }
      }
    }

    .footer-copyright {
      font-size: 18px;
      display: flex !important;
      flex-wrap: wrap !important;
      padding: 0px 10px;
      color: var(--accent);

      p {
        color: var(--white);
      }

      a {
        color: #e97a24;
        text-decoration: none;
      }
    }
  }
}

.gototop {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 10px 10px;
  z-index: 100000;

}

@media only screen and (max-width: 1000px) {
  .footer-parent {
    height: auto;
    background-image: linear-gradient(rgba(0, 0, 0, 0.486), rgba(0, 0, 0, 0.486)), url("../assets/footer-mob.webp") !important;

    .footer-container {
      gap: 30px;
      width: 80%;

      .footer-top-section {
        align-items: start;
        width: 100%;

        .footer-top-heading {
          font-size: 28px;
        }
      }

      .footer-content-box {
        flex-wrap: wrap;

        .footer-pages,
        .footer-services,
        .footer-legal,
        .footer-contact {
          width: 80% !important;
        }
      }
    }
  }

  .footer-services {
    align-items: flex-start !important;
  }
}

@media only screen and (max-width: 600px) {
  .footer-parent {
    height: auto;

    .footer-container {
      width: 100%;

      .footer-top-section {
        align-items: start;

      }

      .footer-content-box {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        grid-template-rows: repeat(3, 1fr) !important;

        .footer-pages {
          grid-area: 1/1/2/3 !important;
        }

        .footer-services {
          grid-area: 2 / 1 / 3 / 2 !important;
        }

        .footer-legal {
          grid-area: 2 / 2 / 3 / 3 !important;
        }

        .footer-contact {
          grid-area: 3 / 1 / 4 / 3 !important;
        }

        gap: 30px;

        .footer-pages,
        .footer-services,
        .footer-legal,
        .footer-contact {
          width: 100% !important;
        }
      }
    }
  }

}

@media only screen and (max-width: 600px) {
  .footer-parent {
    .footer-container {
      .footer-top-section {
        align-items: start;
        gap: 20px;

        .footer-top-heading {
          font-size: 26px;


        }
      }
    }
  }
}

@media only screen and (max-width:450px) {
  .footer-parent {


    .footer-container {
      .footer-top-section {

        .footer-top-heading {
          font-size: 22px;

        }
      }
    }
  }
}