.scroll-header {
  display: flex;
  z-index: 100000000000000000;
  flex-direction: column;
  align-items: end;
  position: fixed;
  top: -20px;
  left: 0px;
  width: 100vw;
  transition: var(--trans);

  .blank-div {
    width: 100%;
    height: 20px;
    background: var(--accent);
    border-radius: 0px 0px 10px 10px;
  }
}

.header2-parent {
  height: auto;
  position: fixed;
  margin-top: 0px;
  // background-color: transparent;
  z-index: 99;
  top: 0px;
  left: 50%;
  // padding: var(--unit) 0px;
  transform: translate(-50%, 0%);
  .book-now-overlay {
    width: 100%;
    height: 0px;
    background-color: var(--accent);
    transition: var(--trans);

    &.expand-height {
      height: 30px;
    }
  }
  .header2-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    margin: 0px auto;

    .h-logo {
      background: url("../assets/logo_white.webp") no-repeat center center;
      background-size: contain;
      width: 20%;
      height: 100%;
    }

    .navbar {
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .h-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: calc(var(--gap) * 2);
        width: 70%;

        a {
          display: flex;
          width: 100px;
          text-decoration: none;
          color: var(--white);

          &.journey {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: calc(var(--gap) / 2);
          }
        }
      }

      .h-booknow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;

        .book-now-button {
          height: auto;
          width: auto;
          text-decoration: none;
          color: white;
          padding: 20px 10px;
          background-color: var(--accent);
          border-radius: 0px 0px 30px 30px;
          transition: var(--trans);
          transition-delay: 0.02s;
          position: relative;
          top: -10px;


          &::before {
            content: "";
            width: 30px;
            height: 30px;
            background-color: rgba(255, 255, 255, 0);
            position: absolute;
            top: -4px;
            left:-19px;
            border-radius: 0px 50px 0px 0px;
            border-right: 10px solid var(--accent);
            border-top: 10px solid var(--accent);
          
          }
          &::after {
            content: "";
            width: 30px;
            height: 30px;
            background-color: rgba(255, 255, 255, 0);
            position: absolute;
            top: -4px;
            right:-20px;
            border-radius: 50px 0px 0px 0px;
            border-top: 10px solid var(--accent);
            border-left: 10px solid var(--accent);
          
          }

        

          &:hover {
            background-color: var(--accent);
            top: -10px !important;
          }
        }
      }
    }
  }

  &.scrolled {
    background-color: var(--black);
    transition: background-color 0.3s ease;
  }
}


.mob-links{
  display: none;
}

.mobnav{
  display: none;
}
// mobile view

@media only screen and (max-width: 800px) {
  .header2-cont {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    .h-logo {
      margin: 0px 10px !important;
      width: 30% !important;
    }

    .mob-links {
      width: 70% !important;
      display: flex !important;
      flex-direction: column !important;
      position: relative !important;
      top: 0px !important;
      align-items: end !important;

      .close {
        display: flex !important;
        height: 100% !important;
        align-items: end !important;
        color: white;
      }
    }
  }
  .mobnav {
    display: flex !important;
    flex-direction: column !important;
    background-color: white !important;
    position: absolute !important;
    top: 0px !important;
    width: 100% !important;
    align-items: center !important;
    gap: var(--gap) !important;
    padding: 20px 0px !important;
    height: 100vh !important;

    .close-btn {
      width: 100%;
      display: flex;
      justify-content: end;
      padding: 20px 20px;

      svg {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        background-color: var(--accent);
        // padding: 20px 20px;
      }
    }

    .moblinks {
      height: 100%;
      width: 100%;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      gap: var(--gap);

      a {
        text-decoration: none !important;
        color: black !important;
      }
    }

    &.scrolled {
      background-color: var(--accent);
    }
  }

  .header2-parent {
    position: fixed !important;
    top: 0px !important;

  
  }
}
