.terms-parent {
  width: 100%;
  height: auto;
  padding-top: 80px;

  .terms-cont {
    display: flex;

    flex-direction: column;
    gap: 6px;

    .term {
      font-size: 30px;
    }

    .list {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: calc(var(--unit) / 2);

      .heading {
        color: var(--accent);
        line-height: 100%;
        font-size: 16px;
        width: 20%;
      }

      p {
        width: 90%;
      }
    }
  }
}