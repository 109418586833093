
.beforef-img {
    width: 100%;
    height: auto;
    position: relative;
    background-image: url("../assets/bg.jpg");
    background-attachment: fixed;
    height: 300px;
  
  
  
  
    .overlay {
      background: rgba(0, 0, 0, 0.377);
      padding: 20px 0px;
  
      height: 100%;
      width: 100%;
  
    }
  
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: calc(var(--gap));
  
  
  
      .heading {
        display: flex;
        align-items: center;
        font-size: 28px;
        letter-spacing: 0.4;
        
        // gap: calc(var(--gap)/2);
  
        span {
          width: 70px;
          height: 2px;
          border-radius: var(--rad);
          background-color: white;
        }
  
        h2 {
          color: white;
        }
      }
  
  
      .para {
        width: 100%;
  
        p {
          text-align: center;
          color: white;
        }
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
    .fixed-img {
  
      .img {
        top: 1px !important;
      }
    }
  }