.service-sectionn {
  // padding: 50px 0px;
  // background: var(--grey);
  .content-box {
    display: flex;
    // margin-top: 50px;
    align-items: center;
    justify-content: center;
  }
  .service-section-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gap);

    .left {
      aspect-ratio: 4/3;
      width: 50%;
      // border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      padding: 50px;
    }
    .right {
      height: 100%;
      width: 50%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      gap: calc(var(--gap) / 2);

      h2 {
        font-size: 1.5em;
        // margin-top: -10px;
        color: var(--black);
      }
      p {
        margin: -5px 0px 5px 0px;
      }
      .list-box {
        display: flex;
        gap: var(--gap);
        .list-one,
        .list-two {
          width: fit-content;
          height: auto;
          display: flex;
          .lists {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            // margin-left: -50px;
            width: 100%;
            align-items: flex-start;
            justify-content: space-between;
            gap: 10px;
            padding: 0;
            li {
              display: flex;
              span {
                padding-right: 10px;
                font-size: 25px;
                color: var(--accent);
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .service-section-cont {
    flex-direction: column !important;
    align-items: center !important;
    gap: var(--gap);

    .left {
      width: 600px !important;
      padding: 30px !important;
    }

    .right {
      width: 80% !important;
      align-items: center !important;

      h2 {
        font-size: 2em !important;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .service-section-cont {
    flex-direction: column !important;
    align-items: center !important;
    gap: var(--gap);

    .left {
      width: 90% !important;
      padding: 10px !important;
    }

    .right {
      width: 95% !important;
      align-items: center !important;

      h2 {
        font-size: 1.8em !important;
      }
    }
  }
}
