.blog-container1 {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  width: 350px;
  margin-top: 25px;

  .img {
    background-image: url("../assets/gallery/gallery-4.jpg");
    width: 350px;
    height: 300px;
  }

  .blog-h3 {
    font-weight: 700;
    font-size: 22px;
    color: var(--black);
    line-height: 25px;
    text-decoration: none;

    a {
      text-decoration: none;
    }
    &:hover {
      color: var(--accent2);
      cursor: pointer;
    }
  }

  .date-time-cat {
    display: flex;
    gap: var(--gap);

    .date {
      border-right: 1px solid rgba(126, 126, 126, 0.397);
      padding: 0px 10px 0px 0px;
      font-weight: 400;
      font-size: 14px;
      color: #8d78a2;
    }

    .category {
      font-weight: 400;
      font-size: 14px;
      color: #8d78a2;
    }

    :hover {
      color: var(--accent2);
      cursor: pointer;
    }
  }

  .para {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: var(--text);
    .c-read {
      font-size: 16px;
      font-weight: 600;
      color: var(--accent2);
      text-decoration: none;
      &:hover {
        color: var(--accent);
        cursor: pointer;
      }
    }
  }
}
