.privacy-parent {
    background-color: var(--white);
    margin:50px 0px;

    .privacy>* {
        padding: 10px 0px;

        h4 {
            font-weight: bold;
        }

    }

    .privacy {

  

        h1 {
            text-align: center;
        }




        .suggest-text {
            font-weight: bold !important;
            color: var(--accent) !important;
            font-size: 17px;

        }


    }
}